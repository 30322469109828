import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {


  public send = false;
  public clientForm: FormGroup;
  public validation_messages = {
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Wrong email'},
      {type: 'not_found', message: 'Email not found'},
    ]
  };

  constructor(private authService: AuthenticationService,
              public router: Router,
              private formBuilder: FormBuilder) {
    this.initClientForm();
  }

  ngOnInit() {
  }

  initClientForm() {
    this.clientForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])]
    });
  }

  recover() {
    this.authService.recoverPassword(this.clientForm.value['email'])
      .then((data) => {
        this.send = true;
      }).catch((e) => {
      console.log(e);
      if (e.code === 404) {
          this.clientForm.get('email').setErrors({not_found: true});
        }
    });
  }

  backToHome() {
    this.router.navigate(['home']);
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }
}
