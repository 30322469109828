import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {

  public appName;
  public showMenu = false;
  // public isAuth = false;
  @Input() isHome;
  @Input() isAnswer;
  @Input() isHIW;

  constructor(private auth: AuthenticationService, public router: Router) {
    this.appName = environment.projectName;
    // this.isAuth = this.auth.isAuthenticated();
  }

  get isAuth() {
    return this.auth.isAuthenticated();
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    this.showMenu = false;
  }

  goToHomePage() {
    this.closeMenu();
    this.router.navigate(['home']);
  }

  goToHIWPage() {
    this.closeMenu();
    this.router.navigate(['how-it-works']);
  }

  goToProfilePage() {
    this.closeMenu();
    this.router.navigate(['profile']);
  }

  goToLoginPage() {
    this.closeMenu();
    this.router.navigate(['login']);
  }

  goToRegisterPage() {
    this.closeMenu();
    this.router.navigate(['register']);
  }

}
