import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.page.html',
  styleUrls: ['./email-confirm.page.scss'],
})
export class EmailConfirmPage implements OnInit {

  public confirmToken: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.confirmToken = params.get('confirmToken');
      this.authService.confirmEmail(this.confirmToken)
        .then((d) => {
          this.router.navigate(['messages', 'pending']);
        })
        .catch((e) => {
          console.error(e);
          this.router.navigate(['login']);
        });
    });
  }

}
