import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {PasswordValidator} from '../../validators/password.validator';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.page.html',
  styleUrls: ['./recover-password.page.scss'],
})
export class RecoverPasswordPage implements OnInit {

  public reset = false;
  public recoverToken: string;
  public clientForm: FormGroup;
  public validation_messages = {
    'newPassword': [
      {type: 'required', message: 'New password is required.'},
      {type: 'minlength', message: 'New password must be at least 5 characters long.'},
      {type: 'maxlength', message: 'New password cannot be more than 25 characters long.'},
      {type: 'pattern', message: 'Your new password must contain only numbers and letters.'},
    ],
    'repeatPassword': [
      {type: 'required', message: 'Repeat password is required.'},
      {type: 'minlength', message: 'Repeat password must be at least 5 characters long.'},
      {type: 'maxlength', message: 'Repeat password cannot be more than 25 characters long.'},
      {type: 'pattern', message: 'Your repeat password must contain only numbers and letters.'},
    ],
    'passwordGroup': [
      {type: 'areEqual', message: 'Your password mismatch.'},
    ]
  };

  constructor(private authService: AuthenticationService,
              public router: Router,
              public route: ActivatedRoute,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.recoverToken = params.get('recoverToken');
      this.initClientForm();
    });
  }

  initClientForm() {
    this.clientForm = this.formBuilder.group({
      passwordGroup: this.formBuilder.group({
        newPassword: ['', Validators.compose([
          Validators.minLength(5),
          Validators.required,
        ])],
        repeatPassword: ['', Validators.required]
      }, {validator: PasswordValidator.areEqual})
    });
  }

  backToHome() {
    this.router.navigate(['home']);
  }

  recover() {
    if (this.recoverToken) {
      this.authService.resetPassword(this.clientForm.value['passwordGroup']['newPassword'], this.recoverToken)
        .then((data) => {
          this.reset = true;
        }).catch((e) => {
          console.error(e);
      });
    }
  }

}
