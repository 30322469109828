import {Component, Input, OnChanges, OnInit, ViewChildren} from '@angular/core';
import {AudioPlayerComponent} from '../audio-player/audio-player.component';
import {ModalController} from '@ionic/angular';
import {AnswerModalComponent} from '../answer-modal/answer-modal.component';
import {MessageService} from '../../services/message/message.service';
import {Subscription} from 'rxjs/index';
import {AudioMessage} from '../../model/audio/message.model';

@Component({
  selector: 'app-audio-player-list',
  templateUrl: './audio-player-list.component.html',
  styleUrls: ['./audio-player-list.component.scss']
})
export class AudioPlayerListComponent implements OnInit, OnChanges {

  public showAudioList = [];
  public audioInActionList = [];
  public subscriptions = {};

  @Input() audioList = [];
  @Input() currentPage: string;
  @ViewChildren(AudioPlayerComponent) audioPlayers;

  constructor(public modalController: ModalController,
              public messageService: MessageService) { }

  ngOnInit() {
    this.audioList = this.messageService.sortMessages(this.audioList);
    this.showAudioList = this.audioList.slice(0, 10);
  }


  ngOnChanges(changes) {
    this.audioList = this.messageService.sortMessages(this.audioList);
    this.showAudioList = this.audioList.slice(0, this.showAudioList.length);
  }

  loadData(infiniteScroll) {
    setTimeout(() => {
      console.log('Done');
      if (this.audioList.length > this.showAudioList.length) {
        this.showAudioList.push(...this.audioList.slice(this.showAudioList.length, this.showAudioList.length + 10));
      }
      infiniteScroll.target.complete();
    }, 50);
  }

  stopOtherPlayer(hash) {
    const audioPlayers = this.audioPlayers['_results'];
    for (let i = 0; i < audioPlayers.length; i++) {
      if (audioPlayers[i].audioMessage.getHash() !== hash && audioPlayers[i].player['_results'][0].isPlay()) {
        audioPlayers[i].player['_results'][0].stop();
      }
    }
  }

  /**
   *
   * @param {string} answerType
   * @param {number} audioId
   */
  showModal(answerType: string, audioId: number) {
    let modal;
    this.modalController.create({
      component: AnswerModalComponent,
      componentProps: {
        answerType: answerType,
        audioId: audioId
      },
      cssClass: 'answer-modal'
    }).then((m) => {
      modal = m;
      modal.present();
    });
  }

  triggerAnswer(audioMessage: AudioMessage) {
    audioMessage.showAnswer = !audioMessage.showAnswer;
  }

  restoreMessage(hash) {
    if (this.audioInActionList.indexOf(hash) === -1) {
      this.audioInActionList.push(hash);
      if (this.currentPage === 'deleted') {
        this.messageService.restoreMsg(hash).then(() => {
          if (this.subscriptions[this.currentPage] instanceof Subscription) { this.subscriptions[this.currentPage].unsubscribe(); }
          this.subscriptions[this.currentPage] = this.updateMessageData(true).subscribe(() => {
            this.removeFromActionList(hash);
          });
        });
      }
    }
  }

  removeAudio(hash) {
    if (this.audioInActionList.indexOf(hash) === -1) {
      this.audioInActionList.push(hash);
      if (this.currentPage !== 'deleted') {
        this.messageService.moveToDeletedMsg(hash).then(() => {
          if (this.subscriptions[this.currentPage] instanceof Subscription) { this.subscriptions[this.currentPage].unsubscribe(); }
          this.subscriptions[this.currentPage] = this.updateMessageData().subscribe(() => {
            this.removeFromActionList(hash);
          });
        });
      } else {
        this.messageService.deleteCompletely(hash).then(() => {
          if (this.subscriptions[this.currentPage] instanceof Subscription) { this.subscriptions[this.currentPage].unsubscribe(); }
          this.subscriptions[this.currentPage] = this.updateMessageData().subscribe(() => {
            this.removeFromActionList(hash);
          });
        });
      }
    }
  }

  triggerFav(index, hash) {
    if (this.audioInActionList.indexOf(hash) === -1) {
      this.audioInActionList.push(hash);
      if (this.audioList[index].getFav()) {
        this.messageService.removeFromFeatured(hash).then(() => {
          if (this.subscriptions[this.currentPage] instanceof Subscription) { this.subscriptions[this.currentPage].unsubscribe(); }
          this.subscriptions[this.currentPage] = this.updateMessageData(true).subscribe(() => {
            this.removeFromActionList(hash);
          });
        });
      } else {
        this.messageService.addToFeatured(hash).then(() => {
          if (this.subscriptions[this.currentPage] instanceof Subscription) { this.subscriptions[this.currentPage].unsubscribe(); }
          this.subscriptions[this.currentPage] = this.updateMessageData(true).subscribe(() => {
            this.removeFromActionList(hash);
          });
        });
      }
    }
  }

  updateMessageData(isFUpdate = false) {
    switch (this.currentPage) {
      case 'pending':
        if (isFUpdate) { this.messageService.getFeaturedMsgs(); }
        return this.messageService.getPendingMsgs();
      case 'answered':
        if (isFUpdate) { this.messageService.getFeaturedMsgs(); }
        return this.messageService.getAnsweredMsgs();
      case 'deleted':
        this.messageService.getPendingMsgs();
        this.messageService.getAnsweredMsgs();
        if (isFUpdate) { this.messageService.getFeaturedMsgs(); }
        return this.messageService.getDeletedMsgs();
      case 'featured':
        this.messageService.getPendingMsgs();
        this.messageService.getAnsweredMsgs();
        this.messageService.getDeletedMsgs();
        return this.messageService.getFeaturedMsgs();
    }
  }

  removeFromActionList(hash) {
    const i = this.audioInActionList.indexOf(hash);
    if (i !== -1) {
      this.audioInActionList.splice(i, 1);
    }
  }
}
