import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../services/message/message.service';

@Component({
  selector: 'app-email-answer',
  templateUrl: './email-answer.page.html',
  styleUrls: ['./email-answer.page.scss'],
})
export class EmailAnswerPage implements OnInit {

  public response: any;
  public emailToken: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.emailToken = params.get('emailToken');
      this.messageService.getAnswer(this.emailToken).then(
        (data) => {
          this.response = data;
        });
    });
  }

}
