import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message/message.service';
import {Subscription} from 'rxjs/index';
import {AudioMessage} from '../../../model/audio/message.model';

@Component({
  selector: 'app-featured',
  templateUrl: '../message.page.html',
  styleUrls: ['./featured.page.scss'],
})
export class FeaturedPage implements OnInit, OnDestroy {

  public audioList = [];
  public subscriptions = {};
  public page = 'featured';

  constructor(public messageService: MessageService) {
    if (this.subscriptions['getFeaturedMsgs'] instanceof Subscription) { this.subscriptions['getFeaturedMsgs'].unsubscribe(); }
    this.subscriptions['getFeaturedMsgs'] = this.messageService.getFeaturedMsgs().subscribe((msgs: AudioMessage[]) => {
        this.audioList = msgs;
      },
      (e) => {
        console.error(e);
      });
  }

  ngOnInit() {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  onChangeFilter(event) {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  ngOnDestroy() {
    if (this.subscriptions['getFeaturedMsgs'] instanceof Subscription) { this.subscriptions['getFeaturedMsgs'].unsubscribe(); }
  }

}
