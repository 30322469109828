import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicStorageModule} from '@ionic/storage';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';


import {AppRoutingModule} from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import {AppComponent} from './app.component';

import {AuthGuardService} from './services/auth-guard/auth-guard.service';
import {UnAuthGuardService} from './services/un-auth-guard/un-auth-guard.service';
import {AuthenticationService} from './services/authentication/authentication.service';
import {MessageService} from './services/message/message.service';
import {ServerService} from './services/server/server.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    IonicModule.forRoot({
      mode: 'md',
      animated: false
    }), AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthenticationService,
    AuthGuardService,
    MessageService,
    ServerService,
    UnAuthGuardService,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
