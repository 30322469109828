import {Injectable} from '@angular/core';
import {ServerService} from '../server/server.service';
import {Events} from '@ionic/angular';
import {AudioMessage} from '../../model/audio/message.model';
import {BehaviorSubject} from 'rxjs/index';
import {Template} from '../../model/template/template.model';
import {TextTemplate} from '../../model/template/text-template.model';
import {AudioTemplate} from '../../model/template/audio-template.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private filter = 'l-first';
  private filterItems = [
    {'name': 'Latest first', 'value': 'l-first', 'selected': true},
    {'name': 'Latest last', 'value': 'l-last', 'selected': false}
  ];

  constructor(public server: ServerService,
              public events: Events) {
  }

  getCurrentFilter() {
    return this.filterItems.find((value, index, array) => {
      return value.selected;
    });
  }

  setFilter(filter) {
    if (this.getCurrentFilter().value !== filter) {
      this.filterItems.forEach((item, i, arr) => {
        arr[i].selected = (filter === item.value);
      });
      this.events.publish('answer:message:filterChange', filter);
      this.events.publish('pending:message:filterChange', filter);
      this.events.publish('featured:message:filterChange', filter);
      this.events.publish('deleted:message:filterChange', filter);
      this.events.publish('message:filterChange', filter);
      this.events.publish('template:filterChange', filter);
    }
  }

  getFilterItems() {
    return this.filterItems;
  }

  /**
   *
   * @param {string} messageHash
   * @param {string} answerType
   * @param {string} answer
   * @param {number} template
   * @param {string} templateName
   * @return {Promise<any>}
   */
  answer(messageHash: string, answerType: string, answer: string, template: number, templateName: string) {
    return this.server.answer({
      hash: messageHash,
      type: answerType,
      answer,
      template,
      template_name: templateName
    });
  }

  templateAnswer(messageHash: string, templateHash: string) {
    return this.server.templateAnswer({
      hash: messageHash,
      template : templateHash
    });
  }

  /**
   *
   * @param hash
   * @return {BehaviorSubject<AudioMessage>}
   */
  getMessage(hash) {
    const b = new BehaviorSubject({});
    this.server.getMessage(hash).subscribe(
      (m) => {
      this.createMessage(m).then(
        (message: AudioMessage) => {
          b.next(message);
        }).catch((e) => {
        b.error(e);
      });
    },
      (e) => {
        b.error(e);
      });
    return b;
  }

  /**
   *
   * @return {BehaviorSubject<AudioMessage[]>}
   */
  getPendingMsgs() {
    const b = new BehaviorSubject([]);
    this.server.getPendingMsgs().subscribe(
      (msgs: any[]) => {
        const m = [];
        for (let i = 0; i < msgs.length; i++) {
          m.push(this.createMessage(msgs[i], 'pending'));
        }
        Promise.all(m).then((messages) => {
          b.next(messages);
        }).catch((e) => {
          b.error(e);
        });
      });
    return b;
  }

  /**
   *
   * @return {BehaviorSubject<AudioMessage[]>}
   */
  getAnsweredMsgs() {
    const b = new BehaviorSubject([]);
    this.server.getAnsweredMsgs().subscribe(
      (msgs: any[]) => {
        const m = [];
        for (let i = 0; i < msgs.length; i++) {
          m.push(this.createMessage(msgs[i], 'answered'));
        }
        Promise.all(m).then((messages) => {
          b.next(messages);
        }).catch((e) => {
          b.error(e);
        });
      });
    return b;
  }

  /**
   *
   * @return {BehaviorSubject<AudioMessage[]>}
   */
  getFeaturedMsgs() {
    const b = new BehaviorSubject([]);
    this.server.getFeaturedMsgs().subscribe(
      (msgs: any[]) => {
        const m = [];
        for (let i = 0; i < msgs.length; i++) {
          m.push(this.createMessage(msgs[i], 'featured'));
        }
        Promise.all(m).then((messages) => {
          b.next(messages);
        }).catch((e) => {
          b.error(e);
        });
      });
    return b;
  }

  /**
   *
   * @return {BehaviorSubject<AudioMessage[]>}
   */
  getDeletedMsgs() {
    const b = new BehaviorSubject([]);
    this.server.getDeletedMsgs().subscribe(
      (msgs: any[]) => {
        const m = [];
        for (let i = 0; i < msgs.length; i++) {
          m.push(this.createMessage(msgs[i], 'deleted'));
        }
        Promise.all(m).then((messages) => {
          b.next(messages);
        }).catch((e) => {
          b.error(e);
        });
      });
    return b;
  }

  /**
   *
   * @param {string} name
   * @param {string} type
   * @param {string} data
   * @return {Promise<any>}
   */
  addTemplate(name: string, type: string, data: string) {
    return this.server.addTemplate({name, type, data});
  }

  /**
   *
   * @param hash
   * @return {BehaviorSubject<Template>}
   */
  getTemplate(hash) {
    const b = new BehaviorSubject({});
    this.server.getTemplate(hash).subscribe(
      (t) => {
        this.createTemplate(t).then(
          (template: AudioMessage) => {
            b.next(template);
          }).catch((e) => {
          b.error(e);
        });
      },
      (e) => {
        b.error(e);
      });
    return b;
  }

  /**
   *
   * @return {BehaviorSubject<Template[]>}
   */
  getTemplates() {
    const b = new BehaviorSubject([]);
    this.server.getTemplates().subscribe(
      (templates: any[]) => {
        const t = [];
        for (let i = 0; i < templates.length; i++) {
          t.push(this.createTemplate(templates[i]));
        }
        Promise.all(t).then((tpls) => {
          b.next(tpls);
        }).catch((e) => {
          b.error(e);
        });
      });
    return b;
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  addToFeatured(hash: string) {
    return this.server.addToFeatured(hash);
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  removeFromFeatured(hash: string) {
    return this.server.removeFromFeatured(hash);
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  moveToDeletedMsg(hash: string) {
    return this.server.moveToDeletedMsg(hash);
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  restoreMsg(hash: string) {
    return this.server.restoreMsg(hash);
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  deleteCompletely(hash: string) {
    return this.server.deleteCompletely(hash);
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  deleteTemplate(hash: string) {
    return this.server.deleteTemplate(hash);
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  getAnswer(hash: string) {
    return new Promise((resolve, reject) => {
      this.server.getAnswer(hash).then((data) => {
        const response = {message: {}, answer: {}};
        if (data['request']) {
          const message = new AudioMessage();
          if (data['request']['status'] === 'new') {
            message.setStatus('pending');
          } else {
            message.setStatus(data['request']['status']);
          }
          message.setUrl(data['request']['question']);
          message.setTime(data['request']['created_at']);
          message.setAuthorName(data['request']['client_name']);

          response['message'] = message;
        }

        if (data['answer'] && data['answer']['answer_type']) {
          let answer;
          if (data['answer']['answer_type'] === 'text') {
            answer = new TextTemplate();
          } else if (data['answer']['answer_type'] === 'voice') {
            answer = new AudioTemplate();
          } else {
            console.error(`Unknown answer type: ${data['answer']['answer_type']}`);
          }
          answer.setData(data['answer']['answer']);
          answer.setTime(data['answer']['answered_at']);
          answer.setName(data['answer']['user_name']);
          answer.setCompany(data['answer']['company']);

          response['answer'] = answer;
        } else {
          response['answer'] = false;
        }

        return resolve(response);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {[AudioMessage]} msgs
   */
  sortMessages(msgs) {
    switch (this.getCurrentFilter().value) {
      case 'l-first':
        return msgs.sort((a: AudioMessage, b: AudioMessage) => (a.getTime() < b.getTime()) ? 1 : ((b.getTime() < a.getTime()) ? -1 : 0)).slice();
      case 'l-last':
        return msgs.sort((a: AudioMessage, b: AudioMessage) => (a.getTime() > b.getTime()) ? 1 : ((b.getTime() > a.getTime()) ? -1 : 0)).slice();
      default:
        return msgs;
    }
  }

  /**
   * @param {{id: number, url: string, date: string, status: string, authorName: string}} data
   * @param status
   * @returns {Promise<AudioMessage>}
   */
  createMessage(data, status?: string) {
    return new Promise((resolve, reject) => {
      const message = new AudioMessage();
      message.setHash(data.hash);
      message.setUrl(data.audio);
      message.setFav(data.fav);
      message.setTime(data.time);
      message.setPhone(data.phone);
      message.setEmail(data.email);
      message.setAuthorName(data.name);
      if (status) {message.setStatus(status); }
      if (data.status) {message.setStatus(data.status); }
      if (status === 'answered' || data.status === 'answered') {
        if (data.answer) {message.setAnswer(data.answer); }
        if (data.answer_at) {message.setAnswerTime(data.answer_at); }
        if (data.answer_type) {message.setAnswerType(data.answer_type); }
      }

      resolve(message);
    });
  }

  createTemplate(data) {
    return new Promise((resolve, reject) => {
      let template;
      if (data.type === 'text') {
        template = new TextTemplate();
      } else if (data.type === 'voice') {
        template = new AudioTemplate();
      } else {
        console.error(`Unknown template type: ${data.type}`);
      }
      template.setHash(data.hash);
      template.setData(data.data);
      template.setName(data.name);
      template.setTime(data.time);
      resolve(template);
    });
  }
}
