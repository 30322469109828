import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import {MessageService} from '../../services/message/message.service';
import {Events} from '@ionic/angular';
import {PlayerComponent} from '../player/player.component';
import {Subscription} from 'rxjs/index';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit, OnChanges {

  public subscriptions = {};
  @Input() templateList = [];
  @Input() canSelect = false;
  @Output() selected = new EventEmitter();
  public selectedTemplate = null;
  public templateInActionList = [];
  @ViewChildren(PlayerComponent) audioPlayers;

  constructor(public messageService: MessageService) {
  }

  ngOnInit() {
    this.templateList = this.messageService.sortMessages(this.templateList);
  }

  ngOnChanges(changes) {
    this.templateList = this.messageService.sortMessages(this.templateList);
  }

  stopOtherPlayer(hash: string) {
    const audioPlayers = this.audioPlayers['_results'];
    for (let i = 0; i < audioPlayers.length; i++) {
      if (audioPlayers[i].audioMessage.getHash() !== hash && audioPlayers[i].isPlay()) {
        audioPlayers[i].stop();
      }
    }
  }

  select(hash: string) {
    if (this.canSelect) {
      this.selectedTemplate = hash;
      this.selected.emit(hash);
    }
  }

  remove(hash: string) {
    this.templateInActionList.push(hash);
    this.messageService.deleteTemplate(hash).then(() => {
      if (this.subscriptions['templates'] instanceof Subscription) { this.subscriptions['templates'].unsubscribe(); }
      this.subscriptions['templates'] = this.messageService.getTemplates()
        .subscribe(() => {
          this.removeFromActionList(hash);
      });
    });
  }

  removeFromActionList(hash) {
    const i = this.templateInActionList.indexOf(hash);
    if (i !== -1) {
      this.templateInActionList.splice(i, 1);
    }
  }
}
