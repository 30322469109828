import {Component, Input, OnDestroy, OnInit, ChangeDetectorRef, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {User} from '../../model/user/user.model';
import {Subscription} from 'rxjs/index';
import {MessageService} from '../../services/message/message.service';
import {AudioMessage} from '../../model/audio/message.model';
import {Template} from '../../model/template/template.model';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit, OnDestroy, OnChanges {

  public currentUser: User;
  public avatar = '';
  public subscriptions = {};
  public pendingCount = 0;
  public answeredCount = 0;
  public featuredCount = 0;
  public templatesCount = 0;
  @Input() activePage: string;

  constructor(public router: Router,
              public auth: AuthenticationService,
              private cdr: ChangeDetectorRef,
              public messageService: MessageService) {

    if (this.subscriptions['currentUser'] instanceof Subscription) {
      this.subscriptions['currentUser'].unsubscribe();
    }
    this.subscriptions['currentUser'] = this.auth.currentUser.subscribe((user: User) => {
      this.currentUser = user;
      this.avatar = this.currentUser.getAvatar();
    });

    if (this.subscriptions['getAnsweredMsgs'] instanceof Subscription) {
      this.subscriptions['getAnsweredMsgs'].unsubscribe();
    }
    this.subscriptions['getAnsweredMsgs'] = this.messageService.getAnsweredMsgs().subscribe(
      (msgs: any[]) => {
        this.answeredCount = msgs.length;
      });

    if (this.subscriptions['getPendingMsgs'] instanceof Subscription) {
      this.subscriptions['getPendingMsgs'].unsubscribe();
    }
    this.subscriptions['getPendingMsgs'] = this.messageService.getPendingMsgs().subscribe((msgs: AudioMessage[]) => {
      this.pendingCount = msgs.length;
    });

    if (this.subscriptions['getFeaturedMsgs'] instanceof Subscription) {
      this.subscriptions['getFeaturedMsgs'].unsubscribe();
    }
    this.subscriptions['getFeaturedMsgs'] = this.messageService.getFeaturedMsgs().subscribe((msgs: AudioMessage[]) => {
      this.featuredCount = msgs.length;
    });

    if (this.subscriptions['getTemplates'] instanceof Subscription) {
      this.subscriptions['getTemplates'].unsubscribe();
    }
    this.subscriptions['getTemplates'] = this.messageService.getTemplates().subscribe((tpls: Template[]) => {
      this.templatesCount = tpls.length;
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.cdr.detectChanges();
  }

  goToPendingPage() {
    if (this.activePage !== 'pending') {
      this.router.navigate(['messages', 'pending']);
    }
  }

  goToProfilePage() {
    this.router.navigate(['profile']);
  }

  goToAnsweredPage() {
    if (this.activePage !== 'answered') {
      this.router.navigate(['messages', 'answered']);
    }
  }

  goToFeaturedPage() {
    if (this.activePage !== 'featured') {
      this.router.navigate(['messages', 'featured']);
    }
  }

  goToDeletedPage() {
    if (this.activePage !== 'deleted') {
      this.router.navigate(['messages', 'deleted']);
    }
  }

  goToTemplatesPage() {
    if (this.activePage !== 'templates') {
      this.router.navigate(['templates']);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions['currentUser'] instanceof Subscription) {
      this.subscriptions['currentUser'].unsubscribe();
    }
    if (this.subscriptions['getTemplates'] instanceof Subscription) {
      this.subscriptions['getTemplates'].unsubscribe();
    }
    if (this.subscriptions['getPendingMsgs'] instanceof Subscription) {
      this.subscriptions['getPendingMsgs'].unsubscribe();
    }
    if (this.subscriptions['getAnsweredMsgs'] instanceof Subscription) {
      this.subscriptions['getAnsweredMsgs'].unsubscribe();
    }
    if (this.subscriptions['getFeaturedMsgs'] instanceof Subscription) {
      this.subscriptions['getFeaturedMsgs'].unsubscribe();
    }
  }

}
