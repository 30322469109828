import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {MessageService} from '../../services/message/message.service';
import {Template} from '../../model/template/template.model';

@Component({
  selector: 'app-add-template-modal',
  templateUrl: './add-template-modal.component.html',
  styleUrls: ['./add-template-modal.component.scss']
})
export class AddTemplateModalComponent implements OnInit {

  public textForm: FormGroup;
  public voiceForm: FormGroup;
  public templateType = 'voice';
  public audioBase64;

  constructor(public formBuilder: FormBuilder,
              private messageService: MessageService,
              private modalController: ModalController) {
    this.initForm();
  }

  ngOnInit() {
  }

  initForm() {
    this.textForm = this.formBuilder.group({
      templateName: [Template.generateName(), Validators.compose([Validators.required])],
      templateText: ['', Validators.compose([Validators.required])]
    });

    this.voiceForm = this.formBuilder.group({
      templateName: [Template.generateName(), Validators.compose([Validators.required])]
    });
  }

  recordStatusHandler(status) {
    return this.audioBase64 = status.audioBase64;
  }

  addTemplate() {
    if (this.templateType === 'voice') {
      this.messageService.addTemplate(this.voiceForm.value['templateName'], this.templateType, this.audioBase64)
        .then((data) => {
          this.messageService.getTemplates();
          this.closeModal();
        });
    } else if (this.templateType === 'text') {
      this.messageService.addTemplate(this.textForm.value['templateName'], this.templateType, this.textForm.value['templateText'])
        .then((data) => {
          this.messageService.getTemplates();
          this.closeModal();
        });
    }
  }

  isValid() {
    if (this.templateType === 'voice') {
      return this.voiceForm.valid && this.audioBase64;
    } else if (this.templateType === 'text') {
      return this.textForm.valid;
    }
  }

  changeTemplateType(type) {
    if (type === 'text') {
      this.audioBase64 = '';
    }
    this.templateType = type;
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
