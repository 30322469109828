import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PasswordValidator} from '../../validators/password.validator';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  public clientForm: FormGroup;
  public changed = false;
  private validation_messages = {
    'oldPassword': [
      {type: 'required', message: 'Old password is required.'},
      {type: 'wrong_password', message: 'Wrong password'}
    ],
    'newPassword': [
      {type: 'required', message: 'New password is required.'},
      {type: 'minlength', message: 'New password must be at least 5 characters long.'},
      {type: 'maxlength', message: 'New password cannot be more than 25 characters long.'},
      {type: 'pattern', message: 'Your new password must contain only numbers and letters.'},
    ],
    'repeatPassword': [
      {type: 'required', message: 'Repeat password is required.'},
      {type: 'minlength', message: 'Repeat password must be at least 5 characters long.'},
      {type: 'maxlength', message: 'Repeat password cannot be more than 25 characters long.'},
      {type: 'pattern', message: 'Your repeat password must contain only numbers and letters.'},
    ],
    'passwordGroup': [
      {type: 'areEqual', message: 'Your password mismatch.'},
    ]
  };

  constructor(public formBuilder: FormBuilder,
              public modalController: ModalController,
              public authenticationService: AuthenticationService) {
    this.clientForm = this.formBuilder.group({
      oldPassword: ['',
        Validators.compose([Validators.required])
      ],
      passwordGroup: this.formBuilder.group({
        newPassword: ['', Validators.compose([
          Validators.minLength(5),
          Validators.required,
          // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$') //this is for the letters (both uppercase and lowercase) and numbers validation
        ])],
        repeatPassword: ['', Validators.required]
      }, {validator: PasswordValidator.areEqual})
    });
  }

  ngOnInit() {
  }

  change() {
    this.authenticationService.changePassword(
      this.clientForm.value['oldPassword'],
      this.clientForm.get('passwordGroup').value['newPassword']).then((data) => {
      this.changed = true;
    }).catch((e) => {
      if (e['code'] == 409) {
        this.clientForm.get('oldPassword').setErrors({wrong_password: true});
      }
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
