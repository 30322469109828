import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message/message.service';
import {Subscription} from 'rxjs/index';

@Component({
  selector: 'app-answered',
  templateUrl: '../message.page.html',
  styleUrls: ['./answered.page.scss'],
})
export class AnsweredPage implements OnInit, OnDestroy {

  public subscriptions = {};
  public audioList = [];
  public page = 'answered';

  constructor(public messageService: MessageService) {
    if (this.subscriptions['getAnsweredMsgs'] instanceof Subscription) { this.subscriptions['getAnsweredMsgs'].unsubscribe(); }
    this.subscriptions['getAnsweredMsgs'] = this.messageService.getAnsweredMsgs().subscribe(
      (msgs: any[]) => {
        this.audioList = msgs;
      },
      (e) => {
        console.error(e);
      });
  }

  ngOnInit() {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  onChangeFilter(event) {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  ngOnDestroy() {
    if (this.subscriptions['getAnsweredMsgs'] instanceof Subscription) { this.subscriptions['getAnsweredMsgs'].unsubscribe(); }
  }
}
