import {AuthenticationService} from './../../services/authentication/authentication.service';
import {Component, OnInit} from '@angular/core';
import {Validators, FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  public clientForm: FormGroup;
  public validation_messages = {
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Wrong email'},
      {type: 'login_err', message: 'Wrong email'}
    ],
    'password': [
      {type: 'required', message: 'Password is required'},
      {type: 'login_err', message: 'Wrong password'}
    ],
  };

  constructor(private authService: AuthenticationService,
              public router: Router,
              private formBuilder: FormBuilder) {
    this.clientForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  login() {
    this.authService.login(this.clientForm.value['email'], this.clientForm.value['password'])
      .then((e) => {
        if (this.authService.isAuthenticated()) {
          this.clientForm.reset();
          this.router.navigate(['messages', 'pending']);
        } else {
          this.clientForm.get('email').setErrors({login_err: true});
          this.clientForm.get('password').setErrors({login_err: true});
        }
      }).catch((e) => {
      this.clientForm.get('email').setErrors({login_err: true});
      this.clientForm.get('password').setErrors({login_err: true});
    });
  }

  goToRegisterPage() {
    this.router.navigate(['register']);
  }

  goToForgotPassPage() {
    this.router.navigate(['forgot-password']);
  }

  resetErrors() {
    if (this.clientForm.get('email').hasError('login_err') || this.clientForm.get('password').hasError('login_err')) {
      this.clientForm.get('email').setErrors({});
      this.clientForm.get('password').setErrors({});
      this.clientForm.get('email').updateValueAndValidity();
      this.clientForm.get('password').updateValueAndValidity();
    }
  }
}
