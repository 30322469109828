import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message/message.service';
import {AudioMessage} from '../../../model/audio/message.model';
import {Subscription} from 'rxjs/index';

@Component({
  selector: 'app-pending',
  templateUrl: '../message.page.html',
  styleUrls: ['./pending.page.scss'],
})
export class PendingPage implements OnInit, OnDestroy {

  public audioList = [];
  public subscriptions = {};
  public page = 'pending';

  constructor(public messageService: MessageService) {
    if (this.subscriptions['getPendingMsgs'] instanceof Subscription) { this.subscriptions['getPendingMsgs'].unsubscribe(); }
    this.subscriptions['getPendingMsgs'] = this.messageService.getPendingMsgs().subscribe((msgs: AudioMessage[]) => {
        this.audioList = msgs;
    },
      (e) => {
        console.error(e);
      });
  }

  ngOnInit() {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  onChangeFilter(event) {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  ngOnDestroy() {
    if (this.subscriptions['getPendingMsgs'] instanceof Subscription) { this.subscriptions['getPendingMsgs'].unsubscribe(); }
  }
}
