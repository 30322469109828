import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Template} from '../../model/template/template.model';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  @Input() template: Template;
  @Output() startPlay = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }
  startPlayEmit(hash) {
    this.startPlay.emit(hash);
  }
}
