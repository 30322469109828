import {Platform} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ServerService} from '../server/server.service';
import {AudioMessage} from '../../model/audio/message.model';
import {User} from '../../model/user/user.model';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  public currentUser = new BehaviorSubject(new User());

  constructor(private server: ServerService, private plt: Platform) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.server.getToken()
      .then(token => {
        if (token) {
          this.getCurrentUserData(token)
            .then((user: User) => {
              this.currentUser.next(user);
              this.server.authenticationState.next(true);
            });
        } else {
          this.server.authenticationState.next(false);
        }
      });
  }

  /**
   *
   * @param {string} token
   */
  getCurrentUserData(token: string) {
    return new Promise((resolve, reject) => {
      this.server.getUserData({token}).then((data) => {
        this.create(data).then((user: User) => {
          resolve(user);
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {string} email
   * @param {string} password
   * @return {Promise<void>}
   */
  login(email: string, password: string) {
    return this.server.login({email, password})
      .then((data) => {
        this.create(data).then((user: User) => {
          this.currentUser.next(user);
          this.server.authenticationState.next(true);
        });
      });
  }

  /**
   *
   * @param {string} name
   * @param {string} email
   * @param {string} phone
   * @param {string} company
   * @param {string} password
   * @return {Promise<void>}
   */
  register(name: string, email: string, phone: string, company: string, password: string) {
    return this.server.register({name, email, phone, company, password});
  }

  /**
   *
   * @param {string} name
   * @param {string} email
   * @param {string} phone
   * @param {string} company
   * @param {string} password
   * @param avatar
   * @return {Promise<void>}
   */
  updateProfile(name: string, email: string, phone: string, company: string, password: string, avatar: string) {
    return new Promise((resolve, reject) => {
      this.server.updateProfile({name, email, phone, company, password, avatar})
        .then((data) => {
          this.create(data['result']).then((user: User) => {
            this.currentUser.next(user);
            resolve(data);
          });
        }).catch((e) => {
        reject(e);
      });
    });
  }

  recoverPassword(email) {
    return this.server.recoverPassword({email});
  }

  /**
   *
   * @param {string} password
   * @param {string} reset_code
   * @return {Promise<any>}
   */
  resetPassword(password: string, reset_code: string) {
    return this.server.resetPassword({password, reset_code});
  }

  /**
   *
   * @param {string} old_password
   * @param {string} new_password
   * @return {Promise<any>}
   */
  changePassword(old_password: string, new_password: string) {
    return this.server.changePassword({old_password, new_password});
  }

  /**
   *
   * @param {string} confirm_code
   */
  confirmEmail(confirm_code: string) {
    return this.server.confirmEmail({confirm_code})
      .then((data) => {
        this.create(data).then((user: User) => {
          this.currentUser.next(user);
          this.server.authenticationState.next(true);
        });
      });
  }

  logout() {
    return this.server.logout().then(() => {
      this.server.authenticationState.next(false);
    });
  }

  /**
   *
   * @return {boolean}
   */
  isAuthenticated() {
    return this.server.authenticationState.value;
  }

  takePhoto($event) {
    return new Promise((resolve, reject) => {
      const file: File = $event.target.files[0];
      if (file && file.size && file.size > 2000000) {
        return reject({code: 413});
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => reject(error);
    });
  }


  create(data) {
    return new Promise((resolve, reject) => {
      const user = new User();
      user.setId(data.user_id);
      user.setPhone(data.phone);
      user.setEmail(data.email);
      user.setAvatar(data.avatar);
      user.setUsername(data.name);
      user.setCompany(data.company);
      user.setWidgetId(data.widget_id);
      resolve(user);
    });
  }
}
