import {Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-record-menu',
  templateUrl: './record-menu.component.html',
  styleUrls: ['./record-menu.component.scss']
})
export class RecordMenuComponent implements OnInit {

  public audioUrl;
  public audioBase64;
  public isStop = false;
  public recordSafeSours;
  @Output() recordStatus = new EventEmitter();
  @Output() startRecordStatus = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  finishRecordHandler(audio) {
    this.isStop = true;
    this.audioUrl = audio.url;
    this.recordSafeSours = audio.safeUrl;
    this.audioBase64 = audio.audioBase64;
    this.recordStatus.next({value: true, audioBase64: this.audioBase64});
  }

  startRecordHandler(status) {
    return this.startRecordStatus.next(status);
  }

  retryRecordHandler(status) {
    this.recordStatus.next({value: false, audioBase64: ''});
    return this.isStop = !status;
  }
}
