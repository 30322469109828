import { Directive } from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appPhoneMask]',
  host: {
    '(ngModelChange)': 'onInputChange($event)',
    '(keydown.backspace)': 'onInputChange($event.target.value, true)'
  }
})
export class PhoneMaskDirective {

  constructor(public model: NgControl) {}

  onInputChange(event, backspace) {
    // remove all mask characters (keep only numeric)
    let newVal = event.replace(/\D/g, '');
    newVal = '+' + newVal;
    this.model.valueAccessor.writeValue(newVal);
  }

}
