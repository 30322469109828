import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {AudioMessage} from '../../model/audio/message.model';
import {AudioTemplate} from '../../model/template/audio-template.model';
import {TextTemplate} from '../../model/template/text-template.model';
import {Template} from '../../model/template/template.model';
import {MessageService} from '../../services/message/message.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs/index';

@Component({
  selector: 'app-answer-modal',
  templateUrl: './answer-modal.component.html',
  styleUrls: ['./answer-modal.component.scss']
})

export class AnswerModalComponent implements OnInit, OnDestroy {

  public audioId: number;
  public clientForm: FormGroup;
  public answerType: string;
  public saveAsTemplate: { isChecked: boolean } = {isChecked: false};
  public audioBase64;
  public recordStatus = false;
  public startRecordStatus = false;
  public templateStatus = false;
  public templateList: Array<Template> = new Array<Template>();
  public selected = '';
  public subscription = {};
  public audioMessage: AudioMessage;

  constructor(private navParams: NavParams,
              public formBuilder: FormBuilder,
              private modalController: ModalController,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.answerType = this.navParams.get('answerType');
    this.formBuild(this.answerType);
    this.audioId = this.navParams.get('audioId');
    if (this.subscription['getMessage'] instanceof Subscription) {
      this.subscription['getMessage'].unsubscribe();
    }
    if (this.subscription['getTemplates'] instanceof Subscription) {
      this.subscription['getTemplates'].unsubscribe();
    }
    this.subscription['getMessage'] = this.messageService.getMessage(this.audioId).subscribe(
      (message: AudioMessage) => {
        if (message instanceof AudioMessage) {
          this.audioMessage = message;
          this.clientForm.get('templateName').setValue(this.audioMessage.getAnswerTemplateName());
        }
      },
      (e) => {
        console.error(e);
      });
    this.subscription['getTemplates'] = this.messageService.getTemplates().subscribe(
      (templates) => {
        templates.forEach((template) => {
          if (this.answerType === 'voice' && template instanceof AudioTemplate) {
            const isPresent = this.templateList.some(t => t.hash === template.hash);
            if (!isPresent) {
              this.templateList.push(template);
            }
          }
          if (this.answerType === 'text' && template instanceof TextTemplate) {
            const isPresent = this.templateList.some(t => t.hash === template.hash);
            if (!isPresent) {
              this.templateList.push(template);
            }
          }
        });
      },
      (e) => {
        console.error(e);
      });
  }

  formBuild(answerType) {
    if (answerType === 'voice') {
      this.clientForm = this.formBuilder.group({
        templateName: ['', Validators.compose([Validators.required])]
      });
    } else if (answerType === 'text') {
      this.clientForm = this.formBuilder.group({
        templateName: ['', Validators.compose([Validators.required])],
        textAnswer: ['', Validators.compose([Validators.required])]
      });
    } else {
      return false;
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  answer() {
      this.messageService.answer(
        this.audioMessage.getHash(),
        this.answerType,
        this.answerType === 'voice' ? this.audioBase64 : this.clientForm.value['textAnswer'],
        this.saveAsTemplate.isChecked  ? 1 : 0,
        this.clientForm.value['templateName']
      ).then((data) => {
        this.messageService.getPendingMsgs();
        this.messageService.getAnsweredMsgs();
        if (this.saveAsTemplate.isChecked) {
          this.messageService.getTemplates();
        }
        this.closeModal();
      });
  }

  sendTemplateAnswer() {
    this.messageService.templateAnswer(
      this.audioMessage.getHash(),
      this.selected
    ).then((data) => {
      this.messageService.getPendingMsgs();
      this.messageService.getAnsweredMsgs();
      this.closeModal();
    });
  }

  recordStatusHandler(status) {
    this.startRecordStatus = false;
    this.audioBase64 = status.audioBase64;
    return this.recordStatus = status.value;
  }

  startRecordStatusHandler(status) {
    return this.startRecordStatus = status;
  }

  useTemplate() {
    return this.templateStatus = true;
  }

  back() {
    this.selected = '';
    return this.templateStatus = false;
  }

  select(hash) {
    this.selected = hash;
  }

  ngOnDestroy() {
    if (this.subscription['getMessage'] instanceof Subscription) {
      this.subscription['getMessage'].unsubscribe();
    }
  }
}
