import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, AfterViewInit {

  public duration = 0;
  public endTime = '00:00';
  public startTime = '00:00';
  @Input() sours: string;
  @Input() recordSafeSours: string;
  @ViewChild('player') player;
  @ViewChild('progressBar') progressBar;
  @Output() startPlay = new EventEmitter();
  @Output() retryRecord = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const getDuration = function (url, next) {
      const _player = new Audio(url);
      _player.addEventListener("durationchange", function (e) {
        if (_player.duration !== Infinity) {
          const duration = _player.duration;
          _player.remove();
          next(duration);
        }
      }, false);
      _player.load();
      _player.currentTime = 24 * 60 * 60;
      _player.volume = 0;
      _player.play();
    };

    getDuration(this.sours, (duration) => {
      // calculate total length of value
      this.duration = duration;
      this.endTime = moment(duration * 1000).format('mm:ss');
    });
  }

  initProgressBar() {
    const current_time = this.player.nativeElement.currentTime || 0;
    // calculate current value time
    // this.startTime = this.calculateCurrentValue(current_time);
    this.startTime = moment(current_time * 1000).format('mm:ss');
    this.progressBar.nativeElement.value = current_time !== 0 ?
      (this.player.nativeElement.currentTime / this.duration) : 0;
  }

  // Controls & Sounds Methods
  // ----------------------------------------------------------

  play() {
    this.startPlay.emit();
    this.player.nativeElement.play();
  }

  pause() {
    if (this.player.nativeElement.paused === false) {
      this.player.nativeElement.pause();
    }
  }

  stop() {
    if (this.player.nativeElement.paused === false) {
      this.player.nativeElement.load();
    }
  }

  isPlay() {
    return (this.player.nativeElement.paused === false);
  }

  togglePlay() {
    if (this.player.nativeElement.paused === false) {
      this.pause();
    } else {
      this.play();
    }
  }

  retry(){
    return this.retryRecord.next(true);
  }

  seek(evt) {
    // const percent = (evt.offsetX / this.progressBar.nativeElement.offsetWidth);
    // this.player.nativeElement.currentTime = percent * this.player.nativeElement.duration;
    // this.progressBar.nativeElement.value = percent / 100;
  }

}
