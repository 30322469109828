import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ProfilePage } from './profile.page';
import {SharedModule} from '../../components/shared.module';
import {ChangePasswordModalComponent} from '../../components/change-password-modal/change-password-modal.component';

const routes: Routes = [
  {
    path: '',
    component: ProfilePage
  }
];

@NgModule({
    entryComponents: [
        ChangePasswordModalComponent
    ],
  imports: [
      ReactiveFormsModule,
      CommonModule,
      FormsModule,
      IonicModule,
      SharedModule,
      RouterModule.forChild(routes)
  ],
  declarations: [
      ProfilePage,
      ChangePasswordModalComponent
  ]
})
export class ProfilePageModule {}
