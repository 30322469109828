import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {SharedModule} from '../../../components/shared.module';

import {PendingPage} from './pending.page';

const routes: Routes = [
  {
    path: '',
    component: PendingPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [PendingPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PendingPageModule {
}
