import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {


  constructor(private auth: AuthenticationService, public router: Router) {
  }

  get isAuth() {
    return this.auth.isAuthenticated();
  }

  ngOnInit() {
  }

  goToLoginPage() {
      this.router.navigate(['login']);
  }

  goToRegisterPage() {
      this.router.navigate(['register']);
  }

}
