import * as moment from 'moment';

export class Template {
  hash: string;
  name: string;
  type: string;
  data: string;
  company: string;
  time = 1;

  constructor() {
  }

  static generateName() {
    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; // January is 0!
    const yyyy: any = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = dd + '/' + mm + '/' + yyyy;

    return `Template_${today}`;
  }

  /**
   *
   * @param {string} hash
   */
  setHash(hash: string) {
    this.hash = hash;
  }

  /**
   *
   * @return {string}
   */
  getHash() {
    return this.hash;
  }

  /**
   *
   * @param {string} name
   */
  setName(name: string) {
    this.name = name;
  }

  /**
   *
   * @return {never}
   */
  getName() {
    return this.name;
  }

  /**
   *
   * @param {string} company
   */
  setCompany(company: string) {
    this.company = company;
  }

  /**
   *
   * @return {never}
   */
  getCompany() {
    return this.company;
  }

  /**
   *
   * @param {string} type
   */
  setType(type: string) {
    this.type = type;
  }

  /**
   *
   * @return {string}
   */
  getType() {
    return this.type;
  }

  /**
   *
   * @param {string} data
   */
  setData(data: string) {
    this.data = data;
  }

  /**
   *
   * @return {string}
   */
  getData() {
    return this.data;
  }

  /**
   *
   * @return {string}
   */
  getTime() {
    return this.time;
  }

  /**
   *
   * @param time
   */
  setTime(time) {
    this.time = time;
  }

  /**
   *
   * @return {string}
   */
  getFormattedTime() {
    const ms: number = (this.time * 1000);
    return moment(ms).format('MMM, DD YYYY') + ' at ' + moment(ms).format('hh:mm a');
  }
}
