import {AuthGuardService} from './services/auth-guard/auth-guard.service';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UnAuthGuardService} from './services/un-auth-guard/un-auth-guard.service';
import {LoginPageModule} from './pages/login/login.module';
import {RegisterPageModule} from './pages/register/register.module';
import {ForgotPasswordPageModule} from './pages/forgot-password/forgot-password.module';
import {RecoverPasswordPageModule} from './pages/recover-password/recover-password.module';
import {EmailConfirmPageModule} from './pages/email-confirm/email-confirm.module';
import {EmailAnswerPageModule} from './pages/email-answer/email-answer.module';
import {ProfilePageModule} from './pages/profile/profile.module';
import {HowItWorksPageModule} from './pages/how-it-works/how-it-works.module';
import {TemplatesPageModule} from './pages/templates/templates.module';
import {PendingPageModule} from './pages/messages/pending/pending.module';
import {AnsweredPageModule} from './pages/messages/answered/answered.module';
import {DeletedPageModule} from './pages/messages/deleted/deleted.module';
import {FeaturedPageModule} from './pages/messages/featured/featured.module';
import {HomePageModule} from './pages/home/home.module';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [UnAuthGuardService],
    // loadChildren: './pages/home/home.module#HomePageModule'
    loadChildren: () => HomePageModule
  },
  {
    path: 'login',
    canActivate: [UnAuthGuardService],
    // loadChildren: './pages/login/login.module#LoginPageModule'
    loadChildren: () => LoginPageModule
  },
  {
    path: 'register',
    canActivate: [UnAuthGuardService],
    // loadChildren: './pages/register/register.module#RegisterPageModule'
    loadChildren: () => RegisterPageModule
  },
  {
    path: 'forgot-password',
    canActivate: [UnAuthGuardService],
    // loadChildren: './pages/forgot-password/forgot-password.module#ForgotPasswordPageModule'
    loadChildren: () => ForgotPasswordPageModule
  },
  {
    path: 'recover-password/:recoverToken',
    canActivate: [UnAuthGuardService],
    // loadChildren: './pages/recover-password/recover-password.module#RecoverPasswordPageModule'
    loadChildren: () => RecoverPasswordPageModule
  },
  {
    path: 'email-confirm/:confirmToken',
    canActivate: [UnAuthGuardService],
    // loadChildren: './pages/email-confirm/email-confirm.module#EmailConfirmPageModule'
    loadChildren: () => EmailConfirmPageModule
  },
  {
    path: 'email-answer/:emailToken',
    canActivate: [UnAuthGuardService],
    // loadChildren: './pages/email-answer/email-answer.module#EmailAnswerPageModule'
    loadChildren: () => EmailAnswerPageModule
  },
  {
    path: 'profile',
    canActivate: [AuthGuardService],
    // loadChildren: './pages/profile/profile.module#ProfilePageModule'
    loadChildren: () => ProfilePageModule
  },
  {
    path: 'how-it-works',
    // loadChildren: './pages/how-it-works/how-it-works.module#HowItWorksPageModule'
    loadChildren: () => HowItWorksPageModule
  },
  {
    path: 'templates',
    canActivate: [AuthGuardService],
    // loadChildren: './pages/templates/templates.module#TemplatesPageModule'
    loadChildren: () => TemplatesPageModule
  },
  {
    path: 'messages',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'pending',
        // loadChildren: './pages/messages/pending/pending.module#PendingPageModule'
        loadChildren: () => PendingPageModule
      },
      {
        path: 'answered',
        // loadChildren: './pages/messages/answered/answered.module#AnsweredPageModule'
        loadChildren: () => AnsweredPageModule
      },
      {
        path: 'deleted',
        // loadChildren: './pages/messages/deleted/deleted.module#DeletedPageModule'
        loadChildren: () => DeletedPageModule
      },
      {
        path: 'featured',
        // loadChildren: './pages/messages/featured/featured.module#FeaturedPageModule'
        loadChildren: () => FeaturedPageModule
      },
    ]
  },
  {path: '**', redirectTo: ''},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
