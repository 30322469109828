import {Template} from './template.model';

export class AudioTemplate extends Template {
  public type = 'voice';

  getUrl() {
    return this.getData();
  }

  getAuthorName() {
    return this.getName();
  }
}
