import {Component, EventEmitter, Input, OnInit, Output, ViewChildren} from '@angular/core';
import {AudioMessage} from '../../model/audio/message.model';
import {PlayerComponent} from '../player/player.component';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent implements OnInit {

  @Input() audioMessage: AudioMessage;
  @ViewChildren(PlayerComponent) player;
  @Output() startPlay = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  startPlayEmit() {
    this.startPlay.emit(this.audioMessage.getHash());
  }

}
