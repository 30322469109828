import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {User} from '../../model/user/user.model';
import {Subscription} from 'rxjs/index';
import {WIDGET_LINK} from '../../../environments/environment';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.page.html',
  styleUrls: ['./how-it-works.page.scss'],
})
export class HowItWorksPage implements OnInit, OnDestroy {

  public widgetId = 'your-widget-id';
  public widgetLink = 'your-widget-id';
  public subscriptions = {};

  constructor(private auth: AuthenticationService) {
    this.widgetLink = WIDGET_LINK;
  }

  get isAuth() {
    return this.auth.isAuthenticated();
  }

  ngOnInit() {
    if (this.subscriptions['currentUser'] instanceof Subscription) {
      this.subscriptions['currentUser'].unsubscribe();
    }
    this.subscriptions['currentUser'] = this.auth.currentUser.subscribe((user: User) => {
      if (user.getWidgetId()) {
        this.widgetId = user.getWidgetId();
      }
    });
  }

  copyCode() {
    const el = document.getElementById('code');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = el.innerText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  ngOnDestroy() {
    if (this.subscriptions['currentUser'] instanceof Subscription) {
      this.subscriptions['currentUser'].unsubscribe();
    }
  }

}
