import {Template} from './template.model';

export class TextTemplate extends Template {
  public type = 'text';

  getShortData() {
    return this.add3Dots(this.getData(), 183);
  }

  add3Dots(string, limit) {
    const dots = '...';
    if (string.length > limit) {
      string = string.substring(0, limit) + dots;
    }
    return string;
  }

}
