import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

declare var MediaRecorder: any;
declare var window: any;

@Component({
  selector: 'app-record-player',
  templateUrl: './record-player.component.html',
  styleUrls: ['./record-player.component.scss']
})

export class RecordPlayerComponent implements OnInit, OnDestroy {

  public state: string = 'start';
  public _startTime = 0;
  public time = '00:00';
  public endTime = '00:00';
  private _stopRecordingTimer;
  private _timer;
  private _duration: number = 120000;
  private _audioType: string = 'audio/ogg';
  private _chunks = [];
  private _mediaRecorder;
  public text: {start: string; record: string} =  {
    start: 'Start record your voice answer',
    record: 'Voice recording has started'
  };
  @Output() finishRecord = new EventEmitter();
  @Output() startRecord = new EventEmitter();
  @ViewChild('progressBar') progressBar;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.endTime = moment(this._duration).format('mm:ss');
    navigator.mediaDevices.getUserMedia({audio: true})
        .then(stream => {
          this._mediaRecorder = new MediaRecorder(stream);
          this._chunks = [];
          this._mediaRecorder.addEventListener("dataavailable", event => {
            if (event.data && event.data.size > 0) {
              this._chunks.push(event.data);
            }
          });
        })
  }

  toggleRecordPlayer(side){
    switch(side){
      case 'record':
            this.startRecording(side);
            break;
      case 'stop':
            this.stopRecording(side);
            break;
    }
  }

  private startRecording(side) {
    this.setState(side);
    this.startRecord.next(true);
    this._startTime = Date.now() - 0;
    this._timer = setInterval(() => {
      this.initProgressBar()
    }, 1);

    this._stopRecordingTimer = setTimeout(
        () => {
          this.stopRecording('stop')
        }, this._duration
    );
    this._chunks = [];
    this._mediaRecorder.start();
  }

  private initProgressBar() {
    const current_time = Date.now() - this._startTime;
    this.time = moment(current_time).format('mm:ss');
    this.progressBar.nativeElement.value = current_time !== 0 ?
        (current_time / this._duration) : 0;
  }

  private stopRecording(side){
    clearTimeout(this._stopRecordingTimer);
    clearInterval(this._timer);
    this._mediaRecorder.stop();
    this.setState(side);
    this._mediaRecorder.addEventListener("stop", () => {
      this.saveAudio();
    });
  }

  private saveAudio(){
    const blob = new Blob(this._chunks, {type: this._audioType});
    const audioURL = window.URL.createObjectURL(blob);
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(audioURL);
    //const _player = new Audio(audioURL);
    //_player.play();
    this.saveAudioInBase64(blob, audioURL, safeUrl);
  }

  private saveAudioInBase64(blob, url, safeUrl){
    var reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      var base64data = reader.result;
      this.finishRecord.next({url: url, audioBase64: base64data, safeUrl: safeUrl})
    }
  }


  setState(state){
    return this.state = state;
  }

  ngOnDestroy() {
    clearTimeout(this._stopRecordingTimer);
    clearInterval(this._timer);
  }
}
