import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import {SharedModule} from '../../../components/shared.module';

import { DeletedPage } from './deleted.page';

const routes: Routes = [
  {
    path: '',
    component: DeletedPage
  }
];

@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      SharedModule,
      RouterModule.forChild(routes)
  ],
  declarations: [DeletedPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DeletedPageModule {}
