import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ModalController} from '@ionic/angular';
import {ChangePasswordModalComponent} from '../../components/change-password-modal/change-password-modal.component';
import {User} from '../../model/user/user.model';
import {Subscription} from 'rxjs/index';
import {PhoneValidator} from '../../validators/phone.validator';
import {ServerService} from '../../services/server/server.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})

export class ProfilePage implements OnInit, OnDestroy {

  @ViewChild('layout') canvasRef;
  public clientForm: FormGroup;
  public currentUser: User;
  public currentAvatar: string;
  public avaImg: string;
  public avaUpload = false;
  public subscriptions = {};

  public validation_messages = {
    'name': [
      {type: 'required', message: 'Name is required'},
    ],
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Wrong email'},
    ],
    'phone': [
      {type: 'required', message: 'Phone is required'},
      {type: 'validPhone', message: 'Wrong phone number'},
    ],
    'company': [
      {type: 'required', message: 'Company is required'},
    ],
    'password': [
      {type: 'required', message: 'Password is required'},
      {type: 'password_err', message: 'Wrong password'}
    ],
  };

  constructor(private authService: AuthenticationService,
              private serverService: ServerService,
              public modalController: ModalController,
              private formBuilder: FormBuilder) {
    this.initClientForm();

    if (this.subscriptions['currentUser'] instanceof Subscription) {
      this.subscriptions['currentUser'].unsubscribe();
    }
    this.subscriptions['currentUser'] = this.authService.currentUser.subscribe((user: User) => {
      this.currentUser = user;
      this.currentAvatar = this.currentUser.getAvatar();
      this.clientForm.get('name').setValue(this.currentUser.getUsername());
      this.clientForm.get('email').setValue(this.currentUser.getEmail());
      this.clientForm.get('phone').setValue(this.formatPhone(this.currentUser.getPhone()));
      this.clientForm.get('company').setValue(this.currentUser.getCompany());
    });
  }

  ngOnInit() {
  }

  initClientForm() {
    this.clientForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      phone: ['', Validators.compose([
        Validators.required,
        PhoneValidator.validPhone
      ])],
      company: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  save() {
    this.authService.updateProfile(
      this.clientForm.value['name'],
      this.clientForm.value['email'],
      this.clientForm.value['phone'],
      this.clientForm.value['company'],
      this.clientForm.value['password'],
      this.avaUpload ? this.avaImg : ''
    )
      .then((data) => {
        this.clientForm.get('password').reset();
        this.serverService.presentAlert(
          'Profile data successfully updated!',
          'Success',
          ['OK']
        );
      }).catch((e) => {
      if (e['code'] && e['code'] == 409) {
        this.clientForm.get('password').setErrors({password_err: true});
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  /**
   *
   * @param {string} phone
   * @return {any}
   */
  formatPhone(phone: string) {
    if (phone) {
      let newVal = phone.replace(/\D/g, '');
      newVal = '+' + newVal;
      return newVal;
    }
    return phone;
  }

  /**
   *
   * @param {string} answerType
   * @param {number} audioId
   */
  showChangePasswordModal(answerType: string, audioId: number) {
    let modal;
    this.modalController.create({
      component: ChangePasswordModalComponent,
      componentProps: {
        answerType: answerType,
        audioId: audioId
      },
      cssClass: 'change-password-modal'
    }).then((m) => {
      modal = m;
      modal.present();
    });
  }

  takePhoto($event) {
    this.authService.takePhoto($event).then(
      (data) => {
        this.avaUpload = true;
        const source = new Image();
        source.crossOrigin = 'Anonymous';
        source.onload = () => {
          const iData = this.cropRectangle(source, data);
          this.avaImg = 'data:image/jpeg;base64,' + iData;
        };
        if (typeof data === 'string') {
          source.src = data;
        }
      }).catch((err) => {
        $event.srcElement.value = '';
        if (err.code === 413) {
          this.serverService.presentAlert(
            'Image to big',
            'Error',
            ['OK']
          );
        }
    });
  }

  cropRectangle(image, imageData) {
    const w = image.width,
      h = image.height;
    let width = null;

    if (w === h) {
      const ar = imageData.split('base64,');
      return ar[1];
    }
    const topLeft = {
      x: 0,
      y: 0
    };
    if (w < h) {
      const crop = (h - w) / 2;
      width = w;
      topLeft.y = crop;
    } else {
      const crop = (w - h) / 2;
      width = h;
      topLeft.x = crop;
    }
    const canvas = this.canvasRef.nativeElement;
    const context = canvas.getContext('2d');
    canvas.height = width;
    canvas.width = width;
    context.drawImage(image, topLeft.x, topLeft.y, width, width, 0, 0, width, width);
    const base64ImageData = canvas.toDataURL();
    const ar = base64ImageData.split('base64,');
    return ar[1];
  }


  ngOnDestroy() {
    if (this.subscriptions['currentUser'] instanceof Subscription) {
      this.subscriptions['currentUser'].unsubscribe();
    }
  }

}
