import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TemplatesPage } from './templates.page';
import {SharedModule} from '../../components/shared.module';
import {AddTemplateModalComponent} from '../../components/add-template-modal/add-template-modal.component';

const routes: Routes = [
  {
    path: '',
    component: TemplatesPage
  }
];

@NgModule({
  entryComponents: [
    AddTemplateModalComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [TemplatesPage, AddTemplateModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TemplatesPageModule {}
