import {AfterViewInit, Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MessageService} from '../../services/message/message.service';


@Component({
  selector: 'app-sidebar-filter',
  templateUrl: './sidebar-filter.component.html',
  styleUrls: ['./sidebar-filter.component.scss']
})
export class SidebarFilterComponent implements OnInit, OnChanges, AfterViewInit {

  public filter;
  public clientForm;
  public filterItems;
  @ViewChild('select') select: ElementRef;
  @Output() filterChange = new EventEmitter();

  constructor(private messageService: MessageService) {
    this.filter = this.messageService.getCurrentFilter();
    this.filterItems = this.messageService.getFilterItems();
  }

  ngOnInit() {
    this.filter = this.messageService.getCurrentFilter();
    this.filterItems = this.messageService.getFilterItems();
  }

  ngOnChanges() {
    this.filter = this.messageService.getCurrentFilter();
    this.filterItems = this.messageService.getFilterItems();
  }

  ngAfterViewInit() {
    this.filter = this.messageService.getCurrentFilter();
    this.filterItems = this.messageService.getFilterItems();
  }

  onChange() {
    this.messageService.setFilter(this.select.nativeElement.value);
    this.filter = this.messageService.getCurrentFilter();
    this.filterChange.next(this.select.nativeElement.value);
  }

  onChanges(event) {
    this.messageService.setFilter(event['detail'].value);
    this.filter = this.messageService.getCurrentFilter();
    this.filterChange.next(event['detail'].value);
  }

  getCurrentFilter() {
    return this.messageService.getCurrentFilter();
  }

}
