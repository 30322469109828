export class User {

  id: number;
  phone: string;
  email: string;
  avatar: string;
  company: string;
  widgetId: string;
  username: string;

  constructor() {
  }

  /**
   *
   * @return {number}
   */
  getId() {
    return this.id;
  }

  /**
   *
   * @param {number} id
   */
  setId(id: number) {
    this.id = id;
  }

  /**
   *
   * @return {string}
   */
  getPhone() {
    return this.phone + '';
  }

  /**
   *
   * @param {string} phone
   */
  setPhone(phone: string) {
    this.phone = phone;
  }

  /**
   *
   * @return {string}
   */
  getEmail() {
    return this.email;
  }

  /**
   *
   * @param {string} email
   */
  setEmail(email: string) {
    this.email = email;
  }

  /**
   *
   * @return {string}
   */
  getAvatar() {
    if (this.avatar) {
      const date = new Date();
      return this.avatar + '?' + date.getTime();
    } else {
      return `https://ui-avatars.com/api/?rounded=true&uppercase=true&name=${this.getUsername()}
      &color=ffffff&background=354c6e&length=${this.getUsername().split(' ').length}&font-size=0.6`;
    }
  }

  /**
   *
   * @param {string} avatar
   */
  setAvatar(avatar: string) {
    this.avatar = avatar;
  }

  /**
   *
   * @return {string}
   */
  getUsername() {
    return this.username;
  }

  /**
   *
   * @param {string} username
   */
  setUsername(username: string) {
    this.username = username;
  }

  /**
   *
   * @return {string}
   */
  getCompany() {
    return this.company;
  }

  /**
   *
   * @param {string} company
   */
  setCompany(company: string) {
    this.company = company;
  }

  /**
   *
   * @return {string}
   */
  getWidgetId() {
    return this.widgetId;
  }

  /**
   *
   * @param {string} widgetId
   */
  setWidgetId(widgetId: string) {
    this.widgetId = widgetId;
  }
}
