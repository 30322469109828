import {Subscription} from 'rxjs/index';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../services/message/message.service';
import {Template} from '../../model/template/template.model';
import {AddTemplateModalComponent} from '../../components/add-template-modal/add-template-modal.component';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.page.html',
  styleUrls: ['./templates.page.scss'],
})
export class TemplatesPage implements OnInit, OnDestroy {

  public templateList = [];
  public subscriptions = {};
  public page = 'templates';

  constructor(
    public messageService: MessageService,
    public modalController: ModalController,
  ) {

    if (this.subscriptions['getTemplates'] instanceof Subscription) {
      this.subscriptions['getTemplates'].unsubscribe();
    }
    this.subscriptions['getTemplates'] = this.messageService.getTemplates().subscribe(
      (tpls: Template[]) => {
        this.templateList = tpls;
      },
      (e) => {
        console.error(e);
      });
  }

  ngOnInit() {
    this.templateList = this.messageService.sortMessages(this.templateList);
  }

  onChangeFilter(event) {

    this.templateList = this.messageService.sortMessages(this.templateList);
  }

  /**
   *
   * @param {string} answerType
   * @param {number} audioId
   */
  showModal(answerType: string, audioId: number) {
    let modal;
    this.modalController.create({
      component: AddTemplateModalComponent,
      cssClass: 'template-modal'
    }).then((m) => {
      modal = m;
      modal.present();
    });
  }

  ngOnDestroy() {
    if (this.subscriptions['getTemplates'] instanceof Subscription) { this.subscriptions['getTemplates'].unsubscribe(); }
  }

}
