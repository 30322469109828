import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {PhoneValidator} from '../../validators/phone.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

  public validation_messages = {
    'name': [
      {type: 'required', message: 'Name is required'},
    ],
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Wrong email'},
      {type: 'duplicate', message: 'Email already in use'},
    ],
    'phone': [
      {type: 'required', message: 'Phone is required'},
      {type: 'validPhone', message: 'Wrong phone number'},
    ],
    'company': [
      {type: 'required', message: 'Company is required'},
    ],
    'password': [
      {type: 'required', message: 'Password is required'},
    ],
  };

  public clientForm: FormGroup;
  public registered = false;

  constructor(private authService: AuthenticationService,
              public router: Router,
              private formBuilder: FormBuilder) {
    this.initClientForm();
  }

  ngOnInit() {
  }

  initClientForm() {
    this.clientForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      phone: ['', Validators.compose([
        Validators.required,
        PhoneValidator.validPhone
      ])],
      company: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  register() {
    this.authService.register(
      this.clientForm.value['name'],
      this.clientForm.value['email'],
      this.clientForm.value['phone'],
      this.clientForm.value['company'],
      this.clientForm.value['password'],
    )
      .then((data) => {
        this.registered = true;
      }).catch((e) => {
        console.log(e);
        if (e.code == 403) {
          this.clientForm.get('email').setErrors({duplicate: true});
        }
    });
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }

  backToHome() {
    this.router.navigate(['home']);
  }

}
