import * as moment from 'moment';

export class AudioMessage {
  hash: string;
  audio: string;
  time: number;
  status: string;
  name: string;
  phone: string;
  email: string;
  fav: boolean;
  answerTime: number;
  answerType: string;
  answer: string;
  showAnswer = false;

  constructor() {}

  /**
   *
   * @return {boolean}
   */
  getFav() {
    return this.fav;
  }

  /**
   *
   * @param {boolean} fav
   */
  setFav(fav: boolean) {
    this.fav = fav;
  }

  /**
   *
   * @param {string} hash
   */
  setHash(hash: string) {
    this.hash = hash;
  }

  /**
   *
   * @returns {number}
   */
  getHash() {
    return this.hash;
  }

  /**
   *
   * @param {string} url
   */
  setUrl(url: string) {
    this.audio = url;
  }

  /**
   *
   * @returns {string}
   */
  getUrl() {
    return this.audio;
  }

  /**
   *
   * @param {number} date
   */
  setTime(date: number) {
    this.time = date;
  }

  /**
   *
   * @returns {number}
   */
  getTime() {
    return this.time;
  }

  /**
   *
   * @return {string}
   */
  getFormattedTime() {
    const ms: number = (this.time * 1000);
    return moment(ms).format('MMM, DD YYYY') + ' at ' + moment(ms).format('hh:mm a');
  }

  /**
   *
   * @param {string} status
   */
  setStatus(status: string) {
    this.status = status;
  }

  /**
   *
   * @returns {string}
   */
  getStatus() {
    return this.status;
  }

  /**
   *
   * @param {string} phone
   */
  setPhone(phone: string) {
    this.phone = phone;
  }

  /**
   *
   * @returns {string}
   */
  getPhone() {
    return this.phone;
  }

  /**
   *
   * @param {string} email
   */
  setEmail(email: string) {
    this.email = email;
  }

  /**
   *
   * @returns {string}
   */
  getEmail() {
    return this.email;
  }

  /**
   *
   * @param {string} authorName
   */
  setAuthorName(authorName: string) {
    this.name = authorName;
  }

  /**
   *
   * @returns {string}
   */
  getAuthorName() {
    return this.name;
  }

    /**
     *
     * @returns {string}
     */
  getAnswerTemplateName() {
        let today: any = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1; // January is 0!
        const yyyy: any = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        today = dd + '/' + mm + '/' + yyyy;

    return `Answer-to-${this.name}_${today}`;
  }

  /**
   *
   * @param {string} answer
   */
  setAnswer(answer: string) {
    this.answer = answer;
  }

  /**
   *
   * @return {string}
   */
  getAnswer() {
    return this.answer;
  }

  /**
   *
   * @param {number} answerTime
   */
  setAnswerTime(answerTime: number) {
    this.answerTime = answerTime;
  }

  /**
   *
   * @return {number}
   */
  getAnswerTime() {
    return this.answerTime;
  }

  /**
   *
   * @return {string}
   */
  getFormattedAnswerTime() {
    const ms: number = (this.answerTime * 1000);
    return moment(ms).format('MMM, DD YYYY') + ' at ' + moment(ms).format('hh:mm a');
  }

  /**
   *
   * @param {string} answerType
   */
  setAnswerType(answerType: string) {
    this.answerType = answerType;
  }

  /**
   *
   * @return {string}
   */
  getAnswerType() {
    return this.answerType;
  }

}
