import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { EmailConfirmPage } from './email-confirm.page';
import {SharedModule} from '../../components/shared.module';

const routes: Routes = [
  {
    path: '',
    component: EmailConfirmPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [EmailConfirmPage]
})
export class EmailConfirmPageModule {}
