import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {BehaviorSubject, Subject, throwError} from 'rxjs/index';
import {AudioMessage} from '../../model/audio/message.model';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {SERVER_URL} from '../../../environments/environment';
import {AlertController} from '@ionic/angular';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  private _behaviors: {
    [key: string]: BehaviorSubject<any>
  } = {};
  public authenticationState = new BehaviorSubject(false);

  constructor(private storage: Storage,
              private http: HttpClient,
              public alertController: AlertController) {

  }

  /**
   *
   * @return {HttpHeaders}
   */
  static getHeaders() {
    const  headers = new  HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    headers.set('Accept', '*/*');
    return headers;
  }


  /**
   *
   * @param name
   * @param {{}} type
   * @return {BehaviorSubject}
   */
  getBehavior(name, type = {}) {
    if (!this._behaviors[name]) {
      this._behaviors[name] = new BehaviorSubject({status: 'pending', value: type});
    }
    return this._behaviors[name];
  }

  /**
   *
   * @param {string} type
   * @param {string} link
   * @param params
   * @param handleUnAuth
   * @return {Subscription}
   */
  request(type: string, link: string, params: any, handleUnAuth = true) {
    const s = new Subject();
    const headers = ServerService.getHeaders();
    const toUrlEncoded = obj => Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&');
    this.http.request(type, `${SERVER_URL}${link}`,
      {
        headers: headers,
        body: toUrlEncoded(params)
      }).subscribe((data: any) => {
      // console.log(data);
      if (data.code === 200) {
        s.next(data);
      } else if (data.code === 401 && handleUnAuth) {
        this.storage.remove(TOKEN_KEY)
          .then(() => {
            this.authenticationState.next(false);
          });
        s.error(data);
      } else {
        s.error(data);
      }
    }, (e) => {
        s.error(e);
    });

    return s;
  }


  getToken() {
    return this.storage.get(TOKEN_KEY);
  }

  login(params) {
    return new Promise((resolve, reject) => {
      this.request('POST', 'user/login', params, false).subscribe(
        (data: any) => {
          if (data.result && data.result.token) {
            this.storage.set(TOKEN_KEY, data.result.token)
              .then(() => {
                return resolve(data.result);
              }).catch((e) => {
              console.error(e);
              reject(e);
            });
          } else {
            console.error('No token found');
            reject('No token found');
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  updateProfile(params) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        if (token) {
          params['token'] = token;
          this.request('POST', 'profile/update', params).subscribe(
            (data) => {
              return resolve(data);
            },
            (error) => {
              console.error(error);
              reject(error);
            });
        } else {
          reject('No token');
        }
      });
    });
  }

  register(params) {
    return new Promise((resolve, reject) => {
      this.request('POST', 'user/register', params).subscribe(
        (data) => {
          return resolve(data);
        },
        (error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  getUserData(params) {
    return new Promise((resolve, reject) => {
      this.request('POST', 'user/validate', params).subscribe(
        (data: any) => {
          this.storage.set(TOKEN_KEY, data.result.token)
            .then(() => {
              return resolve(data.result);
            }).catch((e) => {
            reject(e);
          });
        },
        (error) => {
          reject(error);
        });
    });
  }

  recoverPassword(params) {
    return new Promise((resolve, reject) => {
      this.request('POST', 'user/recover', params).subscribe(
        (data: any) => {
          return resolve(data);
        },
        (error) => {
          reject(error);
        });
    });
  }

  resetPassword(params) {
    return new Promise((resolve, reject) => {
      this.request('POST', 'user/reset_confirm', params).subscribe(
        (data: any) => {
          return resolve(data);
        },
        (error) => {
          reject(error);
        });
    });
  }

  changePassword(params) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        if (token) {
          params['token'] = token;
            this.request('POST', 'profile/change_password', params).subscribe(
              (data: any) => {
                return resolve(data);
              },
              (error) => {
                reject(error);
              });
        } else {
          reject('No token');
        }
      });
    });
  }

  answer(params) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        if (token) {
          params['token'] = token;
          this.request('POST', 'requests/answer', params).subscribe(
            (data: any) => {
              return resolve(data);
            },
            (error) => {
              reject(error);
            });
        } else {
          reject('No token');
        }
      });
    });
  }

  templateAnswer(params) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        if (token) {
          params['token'] = token;
          this.request('POST', 'requests/template2answer', params).subscribe(
            (data: any) => {
              return resolve(data);
            },
            (error) => {
              reject(error);
            });
        } else {
          reject('No token');
        }
      });
    });
  }


  confirmEmail(params) {
    return new Promise((resolve, reject) => {
      this.request('POST', 'user/confirm', params).subscribe(
        (data: any) => {
          this.storage.set(TOKEN_KEY, data.result.token)
            .then(() => {
              return resolve(data.result);
            }).catch((e) => {
            reject(e);
          });
        },
        (error) => {
          reject(error);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.request('POST', 'user/logout', {token}).subscribe(
          () => {
            this.storage.remove(TOKEN_KEY)
              .then(() => {
                return resolve();
              })
              .catch((e) => {
                reject(e);
              });
          },
          (error) => {
            reject(error);
          });
      });
    });
  }

  /**
   *
   * @param {string} status
   * @return {BehaviorSubject<any>}
   */
  getMessages(status: string) {
    let behName = '';
    let requestUrl = '';
    switch (status) {
      case 'pending':
        requestUrl = 'requests/new';
        behName = 'pendingMessages';
        break;
      case 'answered':
        requestUrl = 'requests/answered';
        behName = 'answeredMessages';
        break;
      case 'featured':
        requestUrl = 'requests/get_fav';
        behName = 'featuredMessages';
        break;
      case 'deleted':
        requestUrl = 'requests/deleted';
        behName = 'deletedMessages';
        break;
    }

    const beh: BehaviorSubject<any> = this.getBehavior(behName, []);
    this.getToken().then((token) => {
      if (token) {
        this.request('POST', requestUrl, {token}).subscribe(
          (data: any) => {
            if (data.result && data.result.records) {
              for (let i = 0; i < data.result.records.length; i++) {
                const message = data.result.records[i];
                if (!message['status']) { message['status'] = status; }
                // if (!message['status']) { data.result.records[i]['status'] = status; }
                if (status === 'featured') { message['fav'] = true; }
                const behSub = this.getBehavior(`getMessage-${message.hash}`, {});
                behSub.next(message);
              }
              beh.next(data.result.records);
            }
          },
          (e) => {
            beh.error(e);
          });
      } else {
        beh.error('No token');
      }
    }).catch((e) => {
      beh.error(e);
    });
    return beh;
  }


  /**
   *
   * @returns {BehaviorSubject<any>}
   */
  getPendingMsgs() {
    return this.getMessages('pending');
  }

  /**
   *
   * @return {BehaviorSubject<any>}
   */
  getAnsweredMsgs() {
    return this.getMessages('answered');
  }

  /**
   *
   * @return {BehaviorSubject<any>}
   */
  getFeaturedMsgs() {
    return this.getMessages('featured');
  }

  /**
   *
   * @returns {Promise<AudioMessage[]>}
   */
  getDeletedMsgs() {
    return this.getMessages('deleted');
  }

  /**
   *
   * @param {string} hash
   * @returns {Promise<any>}
   */
  moveToDeletedMsg(hash: string) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.request('POST', 'requests/move2del', {token, hash}).subscribe(
          (d) => {
            resolve(d);
          },
          (e) => {
            reject(e);
          });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {string} hash
   * @returns {Promise<any>}
   */
  restoreMsg(hash: string) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.request('POST', 'requests/move2old', {token, hash}).subscribe(
          (d) => {
            resolve(d);
          },
          (e) => {
            reject(e);
          });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {string} hash
   * @returns {Promise<any>}
   */
  deleteCompletely(hash: string) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.request('POST', 'requests/delete', {token, hash}).subscribe(
          (d) => {
            resolve(d);
          },
          (e) => {
            reject(e);
          });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {string} hash
   * @returns {Promise<any>}
   */
  deleteTemplate(hash: string) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.request('POST', 'templates/delete', {token, hash}).subscribe(
          (d) => {
            resolve(d);
          },
          (e) => {
            reject(e);
          });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {string} hash
   * @returns {Promise<any>}
   */
  getMessage(hash: string) {
    return this.getBehavior(`getMessage-${hash}`, {});
  }

  getTemplates() {
    const beh = this.getBehavior(`getTemplates`, []);
    this.getToken().then((token) => {
      this.request('POST', 'requests/templates', {token}).subscribe(
        (data: any) => {
          if (data.result && data.result.records) {
            for (let i = 0; i < data.result.records.length; i++) {
              const template = data.result.records[i];
              const behSub = this.getBehavior(`getTemplate-${template.hash}`, {});
              behSub.next(template);
            }
            beh.next(data.result.records);
          }
        }
      );
    }).catch((e) => {
      beh.error(e);
    });
    return beh;
  }

  /**
   *
   * @param {string} hash
   * @returns {Promise<any>}
   */
  getTemplate(hash: string) {
    return this.getBehavior(`getTemplate-${hash}`, {});
  }

  /**
   *
   * @param params
   * @return {Promise<any>}
   */
  addTemplate(params) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        params['token'] = token;
        this.request('POST', 'templates/add', params).subscribe(
          (data) => {
            return resolve(data);
          },
          (error) => {
            reject(error);
          });
      });
    });
  }


  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  addToFeatured(hash: string) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.request('POST', 'requests/fav', {token, hash}).subscribe(
          (d) => {
            resolve(d);
          },
          (e) => {
            reject(e);
          });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  removeFromFeatured(hash: string) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.request('POST', 'requests/unfav', {token, hash}).subscribe(
          (d) => {
            resolve(d);
          },
          (e) => {
            reject(e);
          });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  /**
   *
   * @param {string} hash
   * @return {Promise<any>}
   */
  getAnswer(hash: string) {
    return new Promise((resolve, reject) => {
      this.request('POST', 'requests/view', {hash}).subscribe(
        (d) => {
          resolve(d['result']);
        },
        (e) => {
          reject(e);
        });
    });
  }

  /**
   *
   * @param {string} message
   * @param {string} header
   * @param buttons
   */
  presentAlert(message: string, header: string, buttons: any) {
    this.alertController.create({
      header: header,
      message: message,
      buttons: buttons
    }).then((alert) => {
      alert.present();
    });
  }
}
