import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HeaderMenuComponent} from './header-menu/header-menu.component';
import {SidebarMenuComponent} from './sidebar-menu/sidebar-menu.component';
import {AudioPlayerComponent} from './audio-player/audio-player.component';
import {AudioPlayerListComponent} from './audio-player-list/audio-player-list.component';
import {CommonModule} from '@angular/common';
import {SidebarFilterComponent} from './sidebar-filter/sidebar-filter.component';
import {OnlyNumberDirective} from '../directives/only-number/only-number.directive';
import {PhoneMaskDirective} from '../directives/phone-mask/phone-mask.directive';
import { TemplateComponent } from './template/template.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { PlayerComponent } from './player/player.component';
import { RecordPlayerComponent } from './record-player/record-player.component';
import { RecordMenuComponent } from './record-menu/record-menu.component';
import { AnswerComponent } from './answer/answer.component';
import {AnswerModalComponent} from './answer-modal/answer-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule
  ],
  entryComponents: [
    SidebarFilterComponent,
    AnswerModalComponent,
  ],
  declarations: [
    AnswerModalComponent,
    PhoneMaskDirective,
    OnlyNumberDirective,
    HeaderMenuComponent,
    SidebarMenuComponent,
    AudioPlayerComponent,
    AudioPlayerListComponent,
    SidebarFilterComponent,
    TemplateComponent,
    TemplateListComponent,
    PlayerComponent,
    RecordPlayerComponent,
    RecordMenuComponent,
    AnswerComponent,
  ],
  exports: [
    AnswerModalComponent,
    PhoneMaskDirective,
    OnlyNumberDirective,
    HeaderMenuComponent,
    SidebarMenuComponent,
    AudioPlayerComponent,
    RecordPlayerComponent,
    RecordMenuComponent,
    AudioPlayerListComponent,
    SidebarFilterComponent,
    TemplateComponent,
    TemplateListComponent,
    AnswerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
}
