import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message/message.service';
import {Subscription} from 'rxjs/index';
import {Events} from '@ionic/angular';

@Component({
  selector: 'app-deleted',
  templateUrl: '../message.page.html',
  styleUrls: ['./deleted.page.scss'],
})
export class DeletedPage implements OnInit, OnDestroy {

  public subscriptions = {};
  public audioList = [];
  public page = 'deleted';

  constructor(public messageService: MessageService, public events: Events) {
    if (this.subscriptions['getDeletedMsgs'] instanceof Subscription) { this.subscriptions['getDeletedMsgs'].unsubscribe(); }
    this.subscriptions['getDeletedMsgs'] = this.messageService.getDeletedMsgs().subscribe(
      (msgs: any[]) => {
        this.audioList = msgs;
      },
      (e) => {
        console.error(e);
      });

    this.events.unsubscribe(this.page + ':message:filterChange');
    this.events.subscribe(this.page + ':message:filterChange',
      (data) => {
        if (this.audioList.length > 0) {
          this.audioList = this.messageService.sortMessages(this.audioList);
        }
      });
  }

  ngOnInit() {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  onChangeFilter(event) {
    this.audioList = this.messageService.sortMessages(this.audioList);
  }

  ngOnDestroy() {
    if (this.subscriptions['getDeletedMsgs'] instanceof Subscription) { this.subscriptions['getDeletedMsgs'].unsubscribe(); }
  }

}
